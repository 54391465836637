<template>
  <ics-dialog-inner :loading="loading.submit" :visible.sync="dialog.update" title="新增附件" submit-title="上传" cancel-title="关闭" class="form-dialog" width="40%" @submit="confirmUpdate" @close="close">
    <div class="form-inner">
      <el-form ref="uploadForm" :model="formInfo" :rules="rules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
        <div class="upload-inner" style="width: 90%; top: 50%; left: 50%; margin-left: 30px; margin-top: 10px;">
          <fero-upload
            ref="upload"
            v-model="formInfo.fileList"
            :limit="limit"
            :action="uploadUrl"
            :headers="headers"
            drag
            :auto-upload="false"
            :before-upload="beforeUploadFile"
            :on-success="onSuccessFile"
            :on-preview="previewFile"
            :on-error="onErrorFile"
            :on-change="onChange"
            :on-remove="onChange"
            :on-exceed="onExceed"
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </fero-upload>
        </div>
      </el-form>
    </div>
  </ics-dialog-inner>
</template>

<script>
import IcsDialogInner from '../dialog-inner'
class FormProp {
  fileList = []
}
export default {
  name: 'UploadFileDialog',
  components: { IcsDialogInner },
  props: {
    // 企业id
    firmId: {
      type: [String, Number],
      default: ''
    },
    // 企业名称
    firmName: {
      type: [String, Number],
      default: ''
    },
    // 业务类型编码
    typeCode: {
      type: [String, Number],
      default: ''
    },
    // 业务类型名称
    typeName: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      limit: 1,
      baseUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/b/p/firm/access/uploadFirmFile',
      uploadUrl: '',
      headers: {
        Token: this.$store.state.token
      },
      formInfo: new FormProp(),
      rules: {
        fileList: [
          { required: true, message: '请上传文件' }
        ]
      },
      dialog: {
        update: false
      },
      loading: {
        submit: false
      }
    }
  },
  methods: {
    confirmUpdate () {
      if (this._.isEmpty(this.formInfo.fileList)) {
        this.$message.error('请上传附件')
        return false
      }
      this.$refs.uploadForm.validate().then(() => {
        this.uploadUrl = `${this.baseUploadUrl}?firmId=${this.firmId}&firmName=${this.firmName}&typeCode=${this.typeCode}&typeName=${this.typeName}`
        this.$nextTick(() => {
          this.loading.submit = true
          this.$refs.upload.submit()
        })
      })
    },
    onChange (file, fileList) {
      this.formInfo.fileList = fileList
      this.$refs.uploadForm.validateField('fileList')
    },
    onExceed () {
      this.$message.error(`一次只能上传${this.limit}个文件`)
    },
    beforeUploadFile (file) {
      return true
    },
    onSuccessFile (res, file) {
      if (res.success) {
        this.$message.success(res.message || '上传成功')
        this.dialog.update = false
        this.$emit('onSuccessFile', res, file)
      } else {
        this.$message.error(res.message || '上传失败')
        this.formInfo.fileList = []
      }
      this.loading.submit = false
    },
    onErrorFile (err, file, fileList) {
      this.$message.error(JSON.parse(err.message).message || '上传失败')
      this.formInfo.fileList = []
      this.loading.submit = false
      if (err.status === 401) {
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
      }
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    },
    close () {
      this.formInfo = new FormProp()
      this.$refs.upload.clearFiles()
      this.$refs.uploadForm.resetFields()
      this.loading.submit = false
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.dialog-footer{
  text-align: center;
}
</style>
