<template>
  <div>
    <el-form ref="appForm" v-loading="loadingDetail.detail" :model="appForm" :rules="rules" label-width="170px" :label-suffix="constants.labelSuffix">
      <ics-page-inner title="企业基本资料">
        <el-col :span="12">
          <el-form-item label="统一社会信用代码">
            <p>{{ utils.isEffectiveCommon(appForm.socialNo) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="企业全称">
            <p>{{ utils.isEffectiveCommon(appForm.companyName) }}</p>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="companyShortName" label="企业简称">
            <ics-item-inner :prop="appForm.companyShortName" :format="utils.isEffectiveCommon">
              <el-input v-model="appForm.companyShortName" placeholder="请输入企业简称" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="regAmount" label="注册资本(元)">
            <ics-item-inner :prop="appForm.regAmount" :format="utils.isEffectiveCommon">
              <el-input v-model="appForm.regAmount" type="number" placeholder="请输入注册资本" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="establishDate" label="成立日期">
            <ics-item-inner :prop="appForm.establishDate" :format="(val)=>utils.dateFormat(val)">
              <ics-search-date :date.sync="appForm.establishDate" type="date" style="width: 100%" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="contactPhone" label="企业联系电话">
            <ics-item-inner :prop="appForm.contactPhone" :format="utils.isEffectiveCommon">
              <el-input v-model="appForm.contactPhone" placeholder="请输入企业联系电话" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col v-if="agencyView()" :span="12">
          <el-form-item prop="usEnterpriseAddress" label="注册地址">
            <ics-item-inner :prop="appForm.usEnterpriseAddress" :format="utils.isEffectiveCommon">
              <address-picker v-model="appForm.usEnterpriseAddress" :is-show-address-info="isShowAddressInfo" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col v-if="!agencyView()" :span="12">
          <el-form-item label="注册地址">
            <address-picker-detail v-model="appForm.usEnterpriseAddress" />
          </el-form-item>
        </el-col>
        <el-col v-if="agencyView()" :span="12">
          <el-form-item prop="dsEnterpriseAddress" label="办公地址">
            <ics-item-inner :prop="appForm.dsEnterpriseAddress" :format="utils.isEffectiveCommon">
              <address-picker v-model="appForm.dsEnterpriseAddress" :is-show-address-info="isShowAddressInfo" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col v-if="!agencyView()" :span="12">
          <el-form-item label="办公地址">
            <address-picker-detail v-model="appForm.dsEnterpriseAddress" />
          </el-form-item>
        </el-col>
        <el-col v-if="!agencyView()" :span="12">
          <el-form-item label="营业执照照片">
            <el-image :src="appForm.licenseUrl" class="avatar" :preview-src-list="[appForm.licenseUrl]" />
          </el-form-item>
        </el-col>
        <el-col v-if="agencyView()" :span="24">
          <el-form-item label="营业执照照片" required>
            <div style="width: 55%; display: table;">
              <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                <el-row>
                  <el-col :span="12" style="width: 120px;">
                    <el-form-item label="" prop="licenseUrl">
                      <el-upload
                        class="avatar-uploader"
                        :action="licenseFileUploadUrl"
                        :headers="headers"
                        :show-file-list="false"
                        :on-success="licenseUrlSuccess"
                        :on-error="onError"
                        :before-upload="licenseUrlUpload"
                      >
                        <img v-if="appForm.licenseUrl" :src="appForm.licenseUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon" />
                        <div slot="tip" class="el-upload__tip" style="width: 100px;">
                          上传营业执照照片
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form-item>
        </el-col>
      </ics-page-inner>
      <ics-page-inner title="企业法定代表人信息">
        <el-col :span="12">
          <el-form-item prop="legalPerson" label="法定代表人姓名">
            <ics-item-inner :prop="appForm.legalPerson" :format="utils.isEffectiveCommon">
              <el-input v-model="appForm.legalPerson" placeholder="请输入法定代表人姓名" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col v-if="!agencyView()" :span="12">
          <el-form-item label="法定代表人联系电话">
            <p>{{ utils.isEffectiveCommon(appForm.firmAccountInfo.mobile) }}</p>
          </el-form-item>
        </el-col>
        <el-col v-if="agencyView()" :span="12">
          <el-form-item prop="legalPhone" label="法定代表人联系电话">
            <ics-item-inner :prop="appForm.legalPhone" :format="utils.isEffectiveCommon">
              <el-input v-model="appForm.legalPhone" placeholder="请输入法定代表人联系电话" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="documentType" label="证件类型">
            <ics-item-inner :prop="appForm.documentType" :format="(val)=>utils.statusFormat(Number(val), 'documentType')">
              <el-select v-model="appForm.documentType" placeholder="请选择证件类型" style="width: 100%" filterable>
                <el-option v-for="item in constants.documentType" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="legalIdentity" label="证件号码">
            <ics-item-inner :prop="appForm.legalIdentity" :format="utils.isEffectiveCommon">
              <el-input v-model="appForm.legalIdentity" placeholder="请输入证件号码" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="email" label="电子邮箱">
            <ics-item-inner :prop="appForm.email" :format="utils.isEffectiveCommon">
              <el-input v-model="appForm.email" placeholder="请输入电子邮箱" />
            </ics-item-inner>
          </el-form-item>
        </el-col>
        <el-col />
        <el-col v-if="!agencyView()" :span="24">
          <el-form-item label="法定代表人身份证照片">
            <el-row>
              <el-col :span="7">
                <el-form-item>
                  <el-image :src="appForm.idCardUrlZ" class="avatar" :preview-src-list="[appForm.idCardUrlZ, appForm.idCardUrlF]" />
                </el-form-item>
              </el-col>
              <el-col :span="7" :pull="2">
                <el-form-item>
                  <el-image :src="appForm.idCardUrlF" class="avatar" :preview-src-list="[appForm.idCardUrlF, appForm.idCardUrlZ]" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
        <el-col v-if="agencyView()" :span="14">
          <el-form-item label="法定代表人身份证照片" required>
            <div style="width: 55%; display: table;">
              <div style="width: 25%;float:left;margin-right:120px; display: table-cell;">
                <el-row>
                  <el-col :span="12" style="width: 120px;">
                    <el-form-item prop="idCardUrlZ">
                      <el-upload
                        class="avatar-uploader"
                        :action="idCardFileZUploadUrl"
                        :headers="headers"
                        :show-file-list="false"
                        :on-success="idCardUrlZSuccess"
                        :on-error="onError"
                        :before-upload="idCardUrlZUpload"
                      >
                        <img v-if="appForm.idCardUrlZ" :src="appForm.idCardUrlZ" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon" />
                        <div slot="tip" class="el-upload__tip">
                          上传身份证正面
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div style="width: 25%;float:left;margin-right:10px; display: table-cell;">
                <el-row>
                  <el-col :span="12" style="width: 120px;">
                    <el-form-item prop="idCardUrlF">
                      <el-upload
                        class="avatar-uploader"
                        :action="idCardFileFUploadUrl"
                        :headers="headers"
                        :show-file-list="false"
                        :on-success="idCardUrlFSuccess"
                        :on-error="onError"
                        :before-upload="idCardUrlFUpload"
                      >
                        <img v-if="appForm.idCardUrlF" :src="appForm.idCardUrlF" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon" />
                        <div slot="tip" class="el-upload__tip" style="width: 100px;">
                          上传身份证反面
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <ics-button-inner v-if="agencyView()" :loading="loadingDetail.submit" submit-title="下一步" cancel-title="返回" @submit="submitForms" />
      </ics-page-inner>
    </el-form>
    <ics-check-dialog-inner :dialog.sync="checkDialog" :check-info="checkInfo" />
  </div>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import AddressPicker from '@/components/template/address-picker'
import AddressPickerDetail from '@/components/template/address-picker-detail'
import IcsSearchDate from '@/components/search-date'
import {isIdentityId} from '../../../components/template/validate'
import IcsCheckDialogInner from './check-dialog-inner'
export default {
  name: 'IcsCustomerInner',
  components: {IcsSearchDate, IcsCheckDialogInner, AddressPicker, AddressPickerDetail},
  mixins: [routeEnterMixin],
  props: {
    loadingDetail: {
      type: Object,
      default () {
        return {}
      }
    },
    companyDetail: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    const validatePhone  = (rule, value, callback) => {
      if (value.indexOf('-') !== -1) {
        if (!(/^((0\d{2,3})-)?(\d{7,8})(-(\d{3,4}))?$/.test(value))) {
          callback(new Error('请输入正确的联系电话'))
        } else {
          callback();
        }
      } else {
        if (/^\d{3}-\d{8}|\d{4}-\d{7,8}$/.test(value) || /^1[3|4|5|7|8|9][0-9]\d{8}$/.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的联系电话'))
        }
      }
    }
    const checkIdentitytionId  = (rule, value, callback) => {
      if (Number(this.appForm.documentType) === 1) {
        let errorMsg = isIdentityId(value);
        if (errorMsg != '') {
          callback(new Error(errorMsg));
        } else {
          callback()
        }
      } else if (Number(this.appForm.documentType) === 2) {
        const passportReg = /^\d{8}|^[a-zA-Z0-9]{10}|^\d{18}$/
        if (passportReg.test(value)) {
          callback()
        } else {
          callback(new Error('护照号码格式不正确'))
        }
      } else if (Number(this.appForm.documentType) === 3) {
        callback()
      } else if (Number(this.appForm.documentType) === 4) {
        callback()
      } else {
        callback()
      }
    }
    const checkEmail = (rule, value, callback) => {
      if (value) {
        if (/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)) {
          callback()
        } else {
          callback(new Error('邮箱格式不正确'))
        }
      }
      callback()
    }
    return {
      fileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/b/p/firm/access/upload',
      licenseFileUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/b/p/firm/access/uploadOfLicense',
      idCardFileZUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + `/tenant/b/p/firm/access/uploadOfIdCard?side=0`,
      idCardFileFUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + `/tenant/b/p/firm/access/uploadOfIdCard?side=1`,
      isShowAddressInfo: false,
      headers: {
        Token: this.$store.state.token
      },
      appForm: {
        companyShortName: '',
        regAmount: '',
        establishDate: '',
        tel: '',
        usEnterpriseAddress: [],
        dsEnterpriseAddress: [],
        licenseUrl: '',
        legalPerson: '',
        contactPhone: '',
        legalPhone: '',
        documentType: 1,
        legalIdentity: '',
        email: '',
        idCardUrlZ: '',
        idCardUrlF: '',
        licenseType: '',
        licenseOperatingPeriod: '',
        idCardValidPeriod: ''
      },
      checkInfo: {},
      checkDialog: {
        licenseUrl: { visible: false,title: '营业执照信息' },
        idCardUrlZ: { visible: false,title: '身份证正面信息' },
        idCardUrlF: { visible: false,title: '身份证反面信息' },
      },
      rules: {
        companyShortName: [
          { required: true, message: '请输入企业简称', trigger: 'blur' }
        ],
        establishDate: [
          { required: true, message: '请选择成立日期', trigger: 'change' }
        ],
        regAmount: [
          { required: true, message: '请输入注册资本', trigger: 'blur' },
          { max: 20, message: '注册资本最大长度20', trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        usEnterpriseAddress: [
          { required: true, message: '请选择注册地址', trigger: 'change' }
        ],
        dsEnterpriseAddress: [
          { required: true, message: '请选择办公地址', trigger: 'change' }
        ],
        licenseUrl: [
          { required: true, message: '请上传营业执照照片', trigger: 'change' }
        ],
        idCardUrlZ: [
          { required: true, message: '请上传身份证正面', trigger: 'change' }
        ],
        idCardUrlF: [
          { required: true, message: '请上传身份证反面', trigger: 'change' }
        ],
        legalPerson: [
          { required: true, message: '请输入法人姓名', trigger: 'blur' },
          { max: 40, message: '长度最大40个字符', trigger: 'blur' }
        ],
        documentType: [
          { required: true, message: '请选择证件类型', trigger: 'change' }
        ],
        legalPhone: [
          { required: true, message: '请输入法定代表人联系电话', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        legalIdentity: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
          { validator: checkIdentitytionId , trigger: "blur" }
        ],
        email: [
          { required: true, message: '请输入电子邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
      }
    }
  },
  watch: {
    companyDetail: 'initData'
  },
  created () {
    if (this.agencyView()) {
    }
  },
  methods: {
    initData(value) {
      this.appForm = Object.assign(this.appForm, this.utils.filterTrimValue(value))
      console.log(this.appForm)
    },
    agencyView () {
      if (this.$route.params.editMode === 'agency') {
        return true
      } else {
        return false
      }
    },
    licenseUrlSuccess (response, file) {
      this.checkDialog.licenseUrl.visible = true
      const data = response.data
      const bucketParams = this.utils.encryptRSA('firm').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.sysCommFile.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.sysCommFile.url = `${data.sysCommFile.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.checkInfo = data.license
      this.appForm.licenseUrl = data.sysCommFile.url
      this.appForm.licenseType = data.license.licenseType
      this.appForm.licenseOperatingPeriod = data.license.operatingPeriod
    },
    licenseUrlUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'jpeg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 jpg、png、jpeg格式',
          type: 'warning'
        })
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        return isLt
      }
    },
    idCardUrlZSuccess (response) {
      this.checkDialog.idCardUrlZ.visible = true
      const data = response.data
      this.checkInfo = data.idCard
      const bucketParams = this.utils.encryptRSA('firm').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.sysCommFile.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.sysCommFile.url = `${data.sysCommFile.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.idCardUrlZ = data.sysCommFile.url
    },
    idCardUrlZUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'jpeg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 jpg、png、jpeg格式',
          type: 'warning'
        })
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        return isLt
      }
    },
    idCardUrlZRemove (file) {
      this.appForm.idCardUrlZFiles = this.appForm.idCardUrlZFiles.filter(function (item) {
        return item.downloadUrl !== file.downloadUrl
      })
    },
    idCardUrlFSuccess (response, file) {
      this.checkDialog.idCardUrlF.visible = true
      const data = response.data
      this.checkInfo = data.idCard
      const bucketParams = this.utils.encryptRSA('firm').replace(/[+]/g, '%2B')
      const bucketF = bucketParams.replace(/[/]/g, '%2F')
      const bucketD = bucketF.replace(/[=]/g, '%3D')
      const objectKeyParams = this.utils.encryptRSA(data.sysCommFile.path).replace(/[+]/g, '%2B')
      const objectKeyF = objectKeyParams.replace(/[/]/g, '%2F')
      const objectKeyD = objectKeyF.replace(/[=]/g, '%3D')
      data.sysCommFile.url = `${data.sysCommFile.mtype}?bucketParams=${bucketD}&objectKeyParams=${objectKeyD}`
      this.appForm.idCardUrlF = data.sysCommFile.url
      this.appForm.idCardValidPeriod = data.idCard.validPeriod
    },
    idCardUrlFUpload (file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['jpg', 'png', 'jpeg']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message({
          showClose: true,
          message: '上传文件只能是 jpg、png、jpeg格式',
          type: 'warning'
        })
        return false
      }
      const isLt = file.size / 1024 / 1024 < 2
      if (!isLt) {
        this.$message.error('上传文件最大为2M')
        return isLt
      }
    },
    idCardUrlFRemove (file) {
      this.appForm.idCardUrlFFiles = this.appForm.idCardUrlFFiles.filter(function (item) {
        return item.downloadUrl !== file.downloadUrl
      })
    },
    onError (err, file) {
      this.$message.error(JSON.parse(err.message).message || '上传失败')
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        this.$emit('nextStep', this.appForm)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #454545;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px dashed #d9d9d9;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style scoped>
.el-step >>> .el-step__icon {
  width: 40px !important;
  height: 40px !important;
}
.el-step >>> .el-step__line{
  top: 18px !important;
}
.el-step >>>  .is-process .el-step__icon-inner {
  color: #000 !important;
}
.el-step >>> .is-process{
  color: #fff !important;
}
</style>
